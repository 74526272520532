import React, { useRef, useState } from 'react';
import styles from './Sponsors.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

export const Sponsors = () => {

    const sponsorsGridRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - sponsorsGridRef.current.offsetLeft);
        setScrollLeft(sponsorsGridRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - sponsorsGridRef.current.offsetLeft;
        const walk = (x - startX);
        sponsorsGridRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);

    const sponsors = [
        { name: 'Sponsor 1', logo: '/Assets/Modules/Home/sponsors.svg' },
        { name: 'Sponsor 2', logo: '/Assets/Modules/Home/sponsors.svg' },
        { name: 'Sponsor 3', logo: '/Assets/Modules/Home/sponsors.svg' },
    ];

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Connect Solutions",
        "sponsor": sponsors.map(sponsor => ({
            "@type": "Organization",
            "name": sponsor.name,
            "logo": sponsor.logo
        }))
    };

    return (
        <section id='sponsors' className={styles.sponsorsContainer} aria-label="Sponsors and Investors">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <h2 className={styles.sponsorsTitle}>Our Sponsors/ Investors</h2>
            <div
                className={styles.sponsorsGrid}
                ref={sponsorsGridRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                <LazyLoadImage
                    src={'/Assets/Modules/Home/sponsors.svg'}
                    alt={`Sponsor logo`}
                    className={styles.sponsorLogo}
                    draggable='false'
                />
                {sponsors.map((sponsor) => (
                    <LazyLoadImage
                        src={sponsor.logo}
                        alt={`${sponsor.name} logo`}
                        className={styles.sponsorLogo}
                        draggable='false'

                    />
                ))}
            </div>
        </section>
    );
};