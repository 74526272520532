import React, { useEffect, useState } from 'react'
import Header from './Header'
import styles from './Home.module.css'
import { LandingPage } from './LandingPage'
import { Support } from './Support'
import { Services } from './Services'
import { VideoSection } from './VideoSection'
// import Reviews from './Reviews'
import BlogSection from './BlogSection'
import { FaqSection } from './FaqSection'
import { Footer } from './Footer'
import { AppSection } from './AppSection'
import { HomeCarousel } from './HomeCarousel'
import { Sponsors } from './Sponsors'
import { Helmet } from 'react-helmet'
export default function Home() {
  const [activeSection, setActiveSection] = useState('home');

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Connect",
    "description": "Discover a world where convenience meets excellence. Connect brings together healthcare, home services, pet care, entertainment, events, and construction under one powerful platform.",
    "url": "https://connectsolutionss.in",
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61560584010523",
      "https://www.linkedin.com/company/connectsolutionss/",
      "https://x.com/connect_solutns",
      "https://www.instagram.com/connect_solutionss"
    ]
  };

   useEffect(() => {
          const handleScroll = () => {
              const sections = ['home', 'sectors', 'services', 'support', 'faq', 'footer', 'contact', 'blogs', 'sponsors', 'video', 'app'];
              let current = '';
  
              for (const section of sections) {
                  const element = document.getElementById(section);
                  if (element) {
                      const rect = element.getBoundingClientRect();
                      if (rect.top <= window.innerHeight / 3 && rect.bottom >= window.innerHeight / 3) {
                          current = section;
                          break;
                      }
  
                  }
              }
  
              const lastSection = document.getElementById('footer') || document.getElementById('contact');
              if (lastSection && window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                  current = 'contact';
              }
  
              setActiveSection(current);
          };
  
          window.addEventListener('scroll', handleScroll);
          handleScroll();
  
          return () => window.removeEventListener('scroll', handleScroll);
      }, []);

  const getMetadata = () => {
    switch (activeSection) {
      case 'home':
        return {
          title: 'Connect - Simplifying Your Life',
          description: 'Discover a world where convenience meets excellence. Connect brings together healthcare, home services, pet care, entertainment, events, and construction under one powerful platform.'
        };
      case 'support':
        return {
          title: 'Why Choose CONNECT? - Support and Features',
          description: 'Discover why CONNECT is the best choice for your needs. Explore our key value propositions and innovative solutions.'
        };
      case 'sectors':
        return {
          title: 'CONNECT - Simplify Your Life, All in One Place',
          description: `Discover endless possibilities with CONNECT – create events, book services, donate blood, and connect with providers across diverse sectors, all at your fingertips!`
        };
      case 'sponsors':
        return {
          title: 'Our Sponsors and Investors',
          description: 'Meet the sponsors and investors who support our mission and help us grow.'
        };
      case 'services':
        return {
          title: 'Connect - Services',
          description: 'Explore our services and professional categories at CONNECT.'
        };
      case 'video':
        return {
          title: 'CONNECT App Demo Video - See Our App in Action',
          description: 'Watch our CONNECT App demonstration video to see how it can transform your way of living with the best services at your convenience.'
        };
      case 'app':
        return {
          title: 'Download CONNECT Mobile App - Available on Google Play Store',
          description: `Download the CONNECT mobile app from Google Play Store and join our community. Experience convenience at your fingertips!`
        };
        case 'blogs':
        return {
          title: 'CONNECT Blog - Helpful Insights & Stories',
          description: 'Explore our blog for helpful insights and stories about construction, events, pet care, home services, and healthcare.'
        };
        case 'faq':
        return {
          title: 'CONNECT FAQ - Get Answers to Your Questions',
          description: `Find answers to frequently asked questions about CONNECT's services, account management, bookings, payments, and more.`
        };
      default:
        return {
          title: 'Connect - Simplifying Your Life',
          description: 'Discover a world where convenience meets excellence. Connect brings together healthcare, home services, pet care, entertainment, events, and construction under one powerful platform.'
        };
    }
  };

  const { title, description } = getMetadata();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="Connect, healthcare, home services, pet care, entertainment, events, construction" />
        <link rel="canonical" href="https://connectsolutionss.in" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div>
        <Header />
        <main className={styles.contentContainer}>
          <section><LandingPage /></section>
          <section><Support /></section>
          <section><HomeCarousel /></section>
          <section><Sponsors /></section>
          <section><Services /></section>
          <section><VideoSection /></section>
          {/* <section><Reviews /></section> */}
          <section><AppSection /></section>
          <section><BlogSection /></section>
          <section><FaqSection/></section>
          <section><Footer /></section>
        </main>
      </div>
    </>
  )
}
